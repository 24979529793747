.GreenCircle, .RedCircle, .OrangeCircle {
    width: 14px;
    height: 14px;
    border-radius: 7px;
    margin-left: auto;
    margin-right: auto;
    display: inline-block;
    margin-right: 8px
} 

.GreenCircle {
    background-color: green;
}

.RedCircle {
    background-color: red;
}

.OrangeCircle {
    background-color: orange;
}

.BusListRedCell {
    background-color: rgba(165, 38, 38, 0.3);
    text-align: right!important;
}

.BusListGreenCell {
    text-align: right!important;
}

.DevicesStateTable > tbody > tr > td {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;

}

.DevicesStateTable > tbody > tr > td:nth-of-type(5), .DevicesStateTable > tbody > tr > td:nth-of-type(6) {
    text-align: center;
    /* width: 400px; */
}

.DeviceStateContainer {
    margin: 15px;
    height: calc(100% - 60px);
}